import "./App.css";
import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import HomeTwo from "./components/pages/Home02";
import AboutMain from "./components/pages/About";
import CourseOne from "./components/pages/CourseOne";
import CourseTwo from "./components/pages/CourseTwo";
import CourseThree from "./components/pages/CourseThree";
import CourseFour from "./components/pages/CourseFour";
import CourseList from "./components/pages/CourseList";
import CourseDetails from "./components/pages/CourseDetails";
import CourseDetailsTwo from "./components/pages/CourseDetailsTwo";
import InstructorOne from "./components/pages/InstructorPage";
import ContactPage from "./components/pages/ContactPage";
import CategoryPage from "./components/pages/CategoryPage";
import ClassSchedule from "./components/pages/ClassSchedule.jsx";
import ScrollToTop from "../src/components/ScrollToTop.jsx";
import SingleBlog from "../src/components/layouts/blog/SingleBlog.jsx";
import BlogList from "../src/components/layouts/blog/BlogList.jsx";

import FormPage from "./components/layouts/schedule/FormPage.jsx";
import Blog from "./components/pages/Blog.jsx";
import Announcement from "./components/pages/Announcement.jsx";
import AnnouncementDetail from "./components/pages/AnnouncementDetail.jsx";
import { ToastContainer } from "react-toastify";
import CourseAnnouncement from "./components/pages/CourseAnnouncement/CourseAnnouncement.jsx";
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route path="/category/:category" element={<CategoryPage />} />
        <Route path="/SingleBlog/:id" element={<SingleBlog />} />
        <Route path="/blogList" element={<BlogList />} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/"}`}
          element={<HomeTwo />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/ClassSchedule"}`}
          element={<ClassSchedule />}
        />
        <Route path="/form" element={<FormPage />} />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/about"}`}
          element={<AboutMain />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/course-1"}`}
          element={<CourseOne />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/course-2"}`}
          element={<CourseTwo />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/course-3"}`}
          element={<CourseThree />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/course-4"}`}
          element={<CourseFour />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/course-list"}`}
          element={<CourseList />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/course-details/:id"}`}
          element={<CourseDetails />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/course-details-two/:id"}`}
          element={<CourseDetailsTwo />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/instructors"}`}
          element={<InstructorOne />}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/contact"}`}
          element={<ContactPage />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/blog"}`}
          element={<Blog />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/news-events"}`}
          element={<Announcement />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/news/:id/events/:id"}`}
          element={<AnnouncementDetail />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/announcement"}`}
          element={<CourseAnnouncement />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
