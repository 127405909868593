import React from "react";
import {
  Banner,
  Counter,
  CourseGridPopular,
  Features,
  About,
} from "../layouts/home02/index";
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterTwo from "../../common/footer/FooterTwo";
import { Partners } from "../layouts/partners";
import { ContactInfo } from "../layouts/contact";
// import { CategoriesGrid } from "../layouts/categories";
import CategoriesGrid from "../layouts/categorie/CategoriesGrid";
import Popup from "../Popup";

const HomeTwo = () => {
  return (
    <div className="home-page-2">
      <Popup />;
      <HeaderTwo />
      <Banner />
      <Features />
      <About />
      <Counter />
      {/* <CourseGridPopular /> */}
      <CategoriesGrid />
      <Partners />
      <ContactInfo />
      <FooterTwo />
    </div>
  );
};

export default HomeTwo;
