import React from "react";
import Layout from "../../../common/Layout";
import { Link } from "react-router-dom";
const DigitalMediaTraining = () => {
  return (
    <Layout>
      <div className="training-container">
        <header className="training-header">
          <h1>Digital Media Marketing Training Program</h1>
          <p>
            Empowering IT Graduates, Government Officials, and IT Professionals
            with the latest Digital Marketing Skills
          </p>
        </header>

        <main className="training-main">
          <section className="training-section">
            <p>
              We are excited to announce our Digital Media Marketing Training
              program, specially designed for IT graduates, government
              officials, and IT professionals. This program will equip you with
              the skills and knowledge to excel in the digital domain.
            </p>
            <h2>Training Details</h2>
            <ul>
              <li>
                <strong>Mode:</strong> In-person classroom training
              </li>
              <li>
                <strong>Location:</strong> Corvit Networks Peshawar, 1st Floor,
                Ali Tower, University Road
              </li>
              <li>
                <strong>Duration:</strong> 5 consecutive days
              </li>
              <li>
                <strong>Attendance:</strong> 100% participation is mandatory
              </li>
            </ul>
            <div className="info-box">
              <p>🍕Lunch and two tea breaks☕will be provided daily.</p>
            </div>
          </section>

          <section className="training-section">
            <h2>Eligibility Criteria</h2>
            <div className="criteria-grid">
              <div className="criteria-card">
                <h3>IT Graduates</h3>
                <ul>
                  <li>100% free</li>
                  <li>
                    Must provide proof of graduation in IT or a related field
                  </li>
                  <li>Maximum age: 28 years</li>
                  <li>First-come, first-served basis</li>
                </ul>
              </div>
              <div className="criteria-card">
                <h3>Government Officials</h3>
                <ul>
                  <li>100% free</li>
                  <li>Must provide an NOC or Reference Letter</li>
                  <li>Maximum age: 50 years</li>
                  <li>Priority-based seat allocation</li>
                </ul>
              </div>
              <div className="criteria-card">
                <h3>IT Professionals</h3>
                <ul>
                  <li>10% payment of PKR 3,500 (subsidized)</li>
                  <li>Proof of current employment in the IT sector required</li>
                  <li>Maximum age: 50 years</li>
                  <li>First-come, first-served basis</li>
                </ul>
              </div>
            </div>
          </section>

          <section className="training-section">
            <h2>Required Documents for Registration</h2>
            <ul>
              <li>CNIC (Front & Back)</li>
              <li>Degree (Mandatory for fresh graduates)</li>
              <li>CV/Resume</li>
              <li>Bank Draft (For IT Professionals only)</li>
              <li>
                NOC or Reference Letter (For Government Officials and Employees)
              </li>
            </ul>
          </section>

          <section className="training-section">
            <h2>Limited Slots Available!</h2>
            <p className="highlight-text">
              Seats are limited and will be allocated on a first-come,
              first-served basis.
            </p>
          </section>

          <section className="training-section">
            <h2>Contact Us</h2>
            <ul>
              <li>
                <strong>Landline:</strong> 091-5701670, 091-5701671
              </li>
              <li>
                <strong>UAN & WhatsApp:</strong> 0316-9994595
              </li>
              <li>
                <strong>Website:</strong>{" "}
                <a href="https://www.corvit.edu.pk">www.corvit.edu.pk</a>
              </li>
            </ul>
            <p className="footer-note">
              Register now to secure your spot and enhance your digital
              marketing skills! 🚀
            </p>
            <div className="register-button-container">
              <Link to="https://forms.gle/hdB7A6NbiamDPea18">
                <button className="register-button">Register Now</button>
              </Link>
            </div>
          </section>
        </main>

        <style>
          {`
          .info-box p{
           font-size: 1.5rem;
           margin-top:15px;
          }
          .training-container {
      
            background-color: #f8f9fa;
            padding: 20px;
          }
          .training-header {
     
            color: white;
            text-align: center;
            padding: 40px;
            border-radius: 8px;
            margin-bottom: 20px;
          }
          .training-header h1 {
            font-size: 2.5rem;
            margin-bottom: 10px;
          }
          .training-header p {
            font-size: 1.2rem;
          }
          .training-main {
            max-width: 1000px;
            margin: 0 auto;
          }
          .training-section {
            background-color: white;
            padding: 30px;
            margin-bottom: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
          .training-section h2 {
            font-size: 1.8rem;
            margin-bottom: 20px;
            border-bottom: 3px solid #b22e30;
            padding-bottom: 8px;
          }
          .criteria-grid {
            display: grid;
            gap: 20px;
            grid-template-columns: 1fr;
          }
          @media (min-width: 768px) {
            .criteria-grid {
              grid-template-columns: 1fr 1fr 1fr;
            }
          }
          .criteria-card {
            background-color: #f0f4ff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          }
          .info-box {
            background-color: #fff4cc;
            padding: 15px;
            text-align: center;
            border-radius: 8px;
            font-weight: bold;
          }
          .highlight-text {
            color: #dc2626;
            font-weight: bold;
            font-size: 1.2rem;
            text-align: center;
          }
      
 
          .register-button-container {
            text-align: center;
            margin-top: 20px;
          }
          .register-button {
            background-color: #1e40af;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            font-size: 1.2rem;
            cursor: pointer;
            transition: background-color 0.3s;
          }
          .register-button:hover {
            background-color: #2563eb;
          }
        `}
        </style>
      </div>
    </Layout>
  );
};

export default DigitalMediaTraining;
